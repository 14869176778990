import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import WebhookTester from '../../../components/WebhookTester';
export const _frontmatter = {
  "product": "signatures",
  "category": "Webhooks",
  "sort": 2,
  "title": "Tester"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The webhook tester will create signature orders in order to trigger webhook events.`}</p>
    <p>{`You must use TEST environment credentials for this.`}</p>
    <WebhookTester mdxType="WebhookTester" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      